.custom-tooltip {
  background-color: white;
}

.custom-tooltip .label {
  color: black;
  font-weight: bold;
  font-size: 1em;
}

.custom-tooltip .intro {
  color: black;
  font-weight: normal;
  font-size: .9em;
}