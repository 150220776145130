@import "../../assets/fonts";

.chatbot {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: realist, sans-serif;
  display: flex;
  min-width: 900px;

  .main-container {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    flex: 1;

    /*  Diagram  */
    .paper-container {
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      z-index: 1;
      flex: 1;
      background: #F8F9FA;
    }
  }

  /*  Sidebar  */
  .side-bar {
    height: 100%;
    max-width: 280px;
    z-index: 2;
    background: none;
    display: flex;

    scrollbar-width: none;

    .content {
      width: 210px;
      overflow: hidden;
      margin: 0px;
    }
    
    .toggle-bar {
      height: 100%;
      width: 50px;
      background: #222222;
      z-index: 2;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 13px;

      .icon {
        margin-bottom: 26px;
        font-size: 24px;
        color: #FFFFFF;
        cursor: pointer;

        &:before {
          @include icon;
        }
      }

      .toggle-stencil {
        &:before {
          content: '\E39D'
        }
      }

      .toggle-editor {
        &:before {
          content: '\E86F'
        }
      }

      .disabled-icon {
        opacity: 0.35;
      }
    }

    .stencil-container {
      height: 100%;
      position: relative;
    }
  }
}

