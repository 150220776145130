@import "~@joint/plus/joint-plus.css";
@import "./joint-plus/plugins.scss";
@import "./assets/fonts";

.container-chatbot-flow {
  position: relative;
  width: 100%;
  height: 90vh;
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }

}

.container-chatbot-flow {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  user-select: none;
  font-family: sans-serif, Arial;
}


