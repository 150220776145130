/* Estilos para o componente TicketCalendar */

/* Personalização para responsividade do calendário */
.calendar-container {
  width: 100%;
  overflow-x: auto;
}

/* Personalização da barra de ferramentas do calendário */
.rbc-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

.rbc-toolbar button {
  color: #4b5563;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  padding: 6px 12px;
  margin-right: 4px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s;
}

.rbc-toolbar button:hover {
  background-color: #f3f4f6;
}

.rbc-toolbar button.rbc-active {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: white;
}

.rbc-toolbar-label {
  font-weight: 600;
  font-size: 1.125rem;
  color: #1f2937;
  flex-grow: 1;
  text-align: center;
  padding: 0 16px;
}

/* Personalização das células do calendário */
.rbc-month-view {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.rbc-header {
  padding: 8px;
  font-weight: 600;
  color: #4b5563;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #e5e7eb;
}

.rbc-day-bg {
  transition: background-color 0.2s;
}

.rbc-day-bg:hover {
  background-color: #f9fafb;
}

.rbc-off-range-bg {
  background-color: #f3f4f6;
}

.rbc-today {
  background-color: #eff6ff;
}

/* Personalização dos eventos */
.rbc-event {
  background-color: #3b82f6;
  border-radius: 6px;
  padding: 2px 5px;
  font-size: 0.75rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 1px 0;
  cursor: pointer;
}

.rbc-event.rbc-selected {
  background-color: #2563eb;
}

.rbc-event-content {
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Eventos de diferentes status */
.rbc-event-novo {
  background-color: #3b82f6;
}

.rbc-event-em-andamento {
  background-color: #f59e0b;
}

.rbc-event-finalizado {
  background-color: #10b981;
}

/* Personalização da visualização semanal */
.rbc-time-view {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.rbc-time-header {
  border-bottom: 1px solid #e5e7eb;
}

.rbc-time-header-content {
  border-left: 1px solid #e5e7eb;
}

.rbc-time-header-cell {
  padding: 8px;
  font-weight: 600;
  color: #4b5563;
  background-color: #f9fafb;
}

.rbc-time-slot {
  border-top: 1px solid #f3f4f6;
}

.rbc-time-gutter {
  background-color: #f9fafb;
}

/* Personalização da visualização de agenda */
.rbc-agenda-view {
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #e5e7eb;
}

.rbc-agenda-table {
  border: none;
}

.rbc-agenda-time-cell {
  padding: 8px;
  font-weight: 500;
  color: #4b5563;
  border-bottom: 1px solid #e5e7eb;
}

.rbc-agenda-event-cell {
  padding: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.rbc-agenda-date-cell {
  padding: 8px;
  font-weight: 600;
  color: #1f2937;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

/* Ponto de status colorido */
.status-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.status-dot-novo {
  background-color: #3b82f6;
}

.status-dot-em-andamento {
  background-color: #f59e0b;
}

.status-dot-finalizado {
  background-color: #10b981;
}

/* Animações e transições */
.ticket-appear {
  opacity: 0;
  transform: translateY(10px);
}

.ticket-appear-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

/* Responsivo para telas menores */
@media (max-width: 768px) {
  .rbc-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .rbc-toolbar-label {
    margin: 8px 0;
  }
  
  .rbc-btn-group {
    margin-bottom: 8px;
  }
  
  .rbc-event {
    padding: 1px 3px;
    font-size: 0.7rem;
  }
  
  /* Garantir que os dias tenham tamanho uniforme */
  .rbc-date-cell {
    height: 120px;
  }
}

/* Estilos específicos para mobile */
@media (max-width: 480px) {
  .rbc-date-cell {
    height: 100px;
  }
  
  /* Mostrar apenas primeiro caracter do dia da semana */
  .rbc-header {
    font-size: 0.8rem;
  }
  
  /* Reduzir ainda mais o tamanho do texto dos eventos */
  .rbc-event-content {
    font-size: 0.65rem;
  }
  
  /* Ajustar cabeçalho do calendário */
  .calendar-header {
    flex-direction: column;
    gap: 8px;
  }
  
  /* Títulos menores */
  .calendar-title {
    font-size: 1.2rem;
  }
}

/* Adicionar estilos para loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

/* Estilo para os botões de ação nos tickets */
.ticket-actions {
  opacity: 0;
  transition: opacity 0.2s;
}

.ticket-item:hover .ticket-actions {
  opacity: 1;
}

/* Estilo para os badges de contagem */
.count-badge {
  font-size: 0.7rem;
  padding: 2px 6px;
  border-radius: 12px;
  background-color: #e5e7eb;
  color: #4b5563;
  margin-left: 4px;
}

/* Estilos para filtros */
.filter-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

/* Estilos para dias especiais */
.special-day {
  position: relative;
}

.special-day:after {
  content: '';
  position: absolute;
  top: 4px;
  right: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ef4444;
}

/* Ajustes para o modal de detalhes */
.day-detail-modal {
  max-height: 90vh;
  overflow-y: auto;
}

/* Estilos para tooltip de informações */
.ticket-info-tooltip {
  font-size: 0.75rem;
  max-width: 200px;
}

/* Garantir altura uniforme para os dias */
.uniform-day-cell {
  height: 100%;
  min-height: 120px;  /* Altura mínima para todas as células */
  display: flex;
  flex-direction: column;
}

/* Garantir que o grid do calendário seja responsivo */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

@media (max-width: 600px) {
  .calendar-grid {
    gap: 2px;
  }
}

/* Estilos específicos para tablets */
@media (min-width: 481px) and (max-width: 900px) {
  .uniform-day-cell {
    min-height: 150px;
  }
}

/* Estilos específicos para desktop */
@media (min-width: 901px) {
  .uniform-day-cell {
    min-height: 180px;
  }
}