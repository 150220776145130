.box-container {
  margin-bottom: 16px;
  width: 100%;
}

.input-radio-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

input[type="radio"] {
  appearance: auto; /* Para alguns navegadores */
  -webkit-appearance: radio; /* Para navegadores baseados em Webkit */
  -moz-appearance: radio; /* Para Firefox */
  margin: initial;
  padding: initial;
  border: initial;
  outline: initial;
  box-shadow: none;
  background: none;
}


.msg-fetch {
  color: red;
  text-align: center;
  font-size: 14px;
}