.modalRating {
  width: 100%;
  height: 250%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top:30px;
}

.containerModal {
  margin-top: 110px;
  background-color: #fff;
  color: #000;
  width: 50%;
  border-radius: 10px;
  height: fit-content;
}

.flexDisplay {
  display: flex;
  
}

.inlineDisplay {
  display: inline-block;
  margin-left: 12px;
  color:#404040;
}


.content {
  margin: 0px 40px;
  margin-bottom: 15px;
}

.listCalleds {
  padding: 10px;
  height: 100%;
 
}

.attributes {
  margin-bottom: 20px;
  margin-left: 15px;
  margin-left: 5px;
  padding: 8px;
  padding-left: 15px;
}

h4.boldClass p {
  padding-left: 10px;
  padding-top: 8px;
  font-weight: 500;
  color:#404040;
}

h4.boldClass .spantitle {
  padding: 5px;
  font-weight: 700;
  color:#404040;
}

.content .title {
  margin-bottom: 10px;
}

.content .attributes .title .boldClass {
  font-weight: 600;
  color:#404040;
  padding: 0px;
}

.content .evaluate h1.boldClass {
  font-weight: 600;
  color: #404040;
}

.content .technician {
  margin-bottom: 10px;
}

.attributes .margin-classification {
  margin-left: 10px;
}


.closeButton {
  top: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: gray;
}

.buttonSpacing {
  margin: 10px;
  padding: 5px;
}

.closeModals {
  height: 24px !important;
  width: 24px !important;
}

.containerForm {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}


@media (max-width: 575.98px) {

  .modalRating {
    width: 150%;
  }

  .containerModal {
    width: 90%;
  }
  
  .content .evaluate h1.boldClass {
    font-weight: 600;
    color:#404040;
    font-size: 25px;
  }

  h4.boldClass .spantitle {
    padding: 0px;  
    font-weight: 700;
    color:#404040;
  }

  .content .attributes .title .boldClass {
    font-size: 20px;
    font-weight: 600;
    color:#404040;
  }

  h4.boldClass p {
    font-size: 15px;
    padding-left: 10px;
    padding-top: 8px;
    font-weight: 500;
    color:#404040;
  }
}

@media (min-width: 0px) and (max-width: 281.98px) {
  .modalRating {
    width: 170%;
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .containerModal {
    max-width: 85%;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .containerModal {
    max-width: 70%;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .containerModal {
    max-width: 40%;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  
}
