.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
}

.box-form-param {
  display: flex;
  flex-direction: row;
  gap: 8px;
}