.omnichannel {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.omnichannel-main {
  max-width: 100%;
  width: 100%;
  min-height: auto;
  height: 100vh;
  max-height: 88vh;
  display: flex;
  padding-left: 0;
  /*padding-bottom: 45px;*/
  padding-right: 76px;
  margin-left: 0;
}

@media (min-height: 793px) and (max-height: 834.9px) {
  .omnichannel-main {
    max-height: 86vh;
  }
}

@media (min-height: 751.1px) and (max-height: 792.9px) {
  .omnichannel-main {
    max-height: 84vh;
  }
}

@media (min-height: 709.2px) and (max-height: 751px) {
  .omnichannel-main {
    max-height: 82vh;
  }
}

@media (min-height: 667.3px) and (max-height: 709.1px) {
  .omnichannel-main {
    max-height: 80vh;
  }
}

@media (min-height: 625.4px) and (max-height: 667.2px) {
  .omnichannel-main {
    max-height: 78vh;
  }
}

@media (min-height: 583.5px) and (max-height: 625.3px) {
  .omnichannel-main {
    max-height: 75vh;
  }
}

@media (min-height: 541.6px) and (max-height: 583.4px) {
  .omnichannel-main {
    max-height: 72vh;
  }
}

@media (min-height: 499.7px) and (max-height: 541.5px) {
  .omnichannel-main {
    max-height: 67vh;
  }
}

@media (min-height: 457.8px) and (max-height: 499.6px) {
  .omnichannel-main {
    max-height: 68vh;
  }
}

@media (min-height: 415.9px) and (max-height: 457.7px) {
  .omnichannel-main {
    max-height: 66vh;
  }
}

@media (min-height: 374px) and (max-height: 415.8px) {
  .omnichannel-main {
    max-height: 64vh;
  }
}

@media (min-height: 332.1px) and (max-height: 374.9px) {
  .omnichannel-main {
    max-height: 62vh;
  }
}

@media (width: 820px) and (height: 1180px) {
  .omnichannel-main {
    max-height: 93vh;
  }
}

@media (width: 1024px) and (height: 1366px) {
  .omnichannel-main {
    max-height: 100vh;
  }
}

@media (width: 280px) and (height: 653px) {
  .omnichannel-main {
    max-height: 100vh;
  }
}

@media (width: 853px) and (height: 1280px) {
  .omnichannel-main {
    max-height: 95vh;
  }
}
/*@media (max-width: 599.9px) {
  .omnichannel-main {
    padding-bottom: 121px;
  }
} 

@media (min-width: 600px) and (max-width: 959.9px) {
  .omnichannel-main {
    padding-bottom: 90px;
  }
}

@media (min-width: 960px) and (max-width: 1199.9px) {
  .omnichannel-main {
    padding-bottom: 65px;
  }
}

@media (min-width: 1200px) {
  .omnichannel-main {
    padding-bottom: 45px;
  }
}*/