li[class^="ReactFlagsSelect-module_selectOption"] {
  color: black;
}

.reactFlagsSelectCustomHeader button[class^="ReactFlagsSelect-module_selectBtn"] {
  color: white;
  border-color: white;
}

.reactFlagsSelectCustomHeader button[class^="ReactFlagsSelect-module_selectBtn"]::after {
  border-top-color: white;
  border-bottom-color: white;

}

ul[class^="ReactFlagsSelect-module_selectOptions"] {
  width: auto;
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
}

.rightHeaderMenu .MuiList-root .MuiListItem-root .MuiListItemText-inset {
  padding-left: 0;
} 

.HeaderMenuCustomSearchButton:hover {
  background-color: #277afe;
}