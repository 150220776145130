.modalPatrimonio {
  z-index: 999;
  width: 100%;
  height: 500vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.containerModal {
  /*margin-left: 100px;*/
  margin-top: 50px;
  background-color: #fff;
  color: #000;

  border-radius: 10px;
}

.closeButton {
  float: right;
  background-color: transparent;
  border: none;
  outline: none;

  cursor: pointer;
  margin-right: 5px;
  margin-top: 5px;
}

.closeModal {
  height: 35px !important;
  width: 35px !important;
  color: blue !important;
}

.containerForm {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .containerForm {
    width: 100%;
    margin-right: 50px;
  }
  .containerModal {
    margin-bottom: 200px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/*
@media (max-width: 575.98px) {
  .containerForm {
    margin-left: 300px;
    margin-right: 50px;
  }
  .containerModal {
    margin-bottom: 200px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .containerForm {
    margin-left: 300px;
    margin-right: 50px;
  }
  .containerModal {
    margin-bottom: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .containerForm {
    margin-left: 300px;
    margin-right: 50px;
  }
  .containerModal {
    margin-bottom: 150px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .containerForm {
    margin-left: 300px;
    margin-right: 50px;
  }
  .containerModal {
    margin-bottom: 250px;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .containerModal {
    margin-bottom: 300px;
    margin-right: 100px;
  }
}

/*
@media (min-width: 1500px) and (max-width: 1599.98px) {
  .containerModal {
    margin-bottom: 300px;
    margin-right: 100px;
  }
}
*/

@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
