.editor-window {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: -1;
	@include transition(opacity 0.5s);
	@include bg-gradient(top, #074055 0%, #030D10 100%);
	
	&.active {
		opacity: 1;
		z-index: auto;
		
		.video-control {
			z-index: auto;
			
		}
	}	
	.editor-control {
		width: 100%;
		height: 100%;
	}
	
		
}
/*
@include keyframes(in-fadeout) {
	0% {opacity: 1}
	75% {opacity: 1}
	100% {opacity: 0}
}
*/