.calledItem {
  margin-top: 15px!important;
  border-radius: 10px;
  width: 100%;
  background-color: #F6F6F6;
  padding: 10px 20px;
  min-height: 100px;
}

.requestingUser {
  display: flex;
  align-items: center;
}

.mainContent {

}

.boldLine {
  color: #525252;
  font-weight: 600;
  font-size: 1em;
}

.boldLine-two {
  color: #525252;
  font-weight: 600;
  font-size: 1.1em;
}

.moreContent {

}

.slaGreen {
  background-color: rgba(118, 181, 42, .4);
}

.slaGreen .MuiLinearProgress-bar {
  background-color: rgba(118, 181, 42);
}

.slaOrange {
  background-color: rgba(243, 128, 32, .4);
}

.slaOrange .MuiLinearProgress-bar {
  background-color: rgba(243, 128, 32);
}

.slaRed {
  background-color: rgba(243, 32, 42, .4);
}

.slaRed .MuiLinearProgress-bar {
  background-color: rgba(243, 32, 42);
}

.moreActions {
  display: block!important;
}

.slaBlock {
  display: block!important;
}

.moreContent {
  margin-top: 0!important;
}

.slaTooltip {
  display: none!important;
}

.moreActionsSmall {
  display: none!important;
}

@media (max-width: 959.9px) {
  .moreActions {
    display: none!important;
  }

  .moreActionsSmall {
    display: block!important;
  }

  .slaBlock {
    display: none!important;
  }

  .slaTooltip {
    display: block!important;
  }

  .moreContent {
    margin-top: 15px!important;
  }
}
