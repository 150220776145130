.MuiDialog-root .MuiDialog-container .MuiPaper-root {
  overflow: hidden!important;
}

.MuiDataGrid-footerContainer {
  justify-content: flex-end!important;
}

.MuiDataGrid-selectedRowCount {
  display: none!important;
}

.filter-drawer {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms ease, transform 1000ms ease;;
}

.filter-drawer-enter {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 400ms ease, transform 1000ms ease;
}

.filter-drawer-exit {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 400ms ease, transform 1000ms ease;
}

.button-active {
  background-color: #1976d2; /* Cor de fundo para indicar que está ativo */
  color: #fff; /* Cor do texto */
  border: 2px solid #115293; /* Borda para destacar o botão */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Efeito de profundidade */
  transform: translateY(-2px); /* Eleva o botão levemente */
  transition: all 0.2s ease; /* Transição suave */
}

.button-active:hover {
  background-color: #115293; /* Cor ao passar o mouse */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3); /* Aumenta a profundidade ao passar o mouse */
  transform: translateY(-4px); /* Eleva ainda mais ao passar o mouse */
}

.button-active:focus {
  outline: none; /* Remove o contorno padrão */
  border: 2px solid #0d47a1; /* Borda mais escura ao focar */
}