/* tag-input.css */
:root {
    --primary-color: #1976d2;
    --primary-light: #e3f2fd;
    --gray-100: #f5f5f5;
    --gray-200: #eeeeee;
    --gray-300: #e0e0e0;
    --gray-500: #9e9e9e;
    --text-primary: rgba(0, 0, 0, 0.87);
    --text-secondary: rgba(0, 0, 0, 0.6);
  }
  
  .tag-input-container {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    max-width: 100%;
    width: 100%;
  }
  
  .tag-input-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    margin: 1rem 0;
  }
  
  .tag-input-label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
  }
  
  .tag-input-field {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem;
    min-height: 2.5rem;
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background-color: #ffffff;
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .tag-input-field-focused {
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
  }
  
  .tag-input-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
  
  .tag-input-tag {
    display: flex;
    align-items: center;
    background-color: var(--primary-light);
    border-radius: 16px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    color: var(--primary-color);
    margin: 0.25rem;
    height: 28px;
    max-width: 100%;
    overflow: hidden;
  }
  
  .tag-input-tag-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
  
  .tag-input-tag-delete {
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: none;
    border: none;
    padding: 0;
    font-size: 1rem;
    line-height: 1;
    color: var(--primary-color);
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .tag-input-tag-delete:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  
  .tag-input-input {
    flex: 1;
    min-width: 60px;
    border: none;
    outline: none;
    background: transparent;
    font-size: 0.875rem;
    padding: 0.25rem;
    margin: 0.25rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  
  .tag-input-helper {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    color: var(--text-secondary);
  }
  
  /* Extras para uso em aplicações maiores */
  .tag-input-error .tag-input-field {
    border-color: #d32f2f;
  }
  
  .tag-input-error .tag-input-helper {
    color: #d32f2f;
  }
  
  .tag-input-disabled .tag-input-field {
    background-color: #f5f5f5;
    border-color: #e0e0e0;
    color: rgba(0, 0, 0, 0.38);
    cursor: not-allowed;
  }
  
  .tag-input-disabled .tag-input-input {
    cursor: not-allowed;
  }
  
  .tag-input-disabled .tag-input-tag {
    opacity: 0.7;
  }
  
  /* Responsividade */
  @media (max-width: 600px) {
    .tag-input-card {
      padding: 1rem;
    }
    
    .tag-input-tag {
      font-size: 0.8125rem;
      height: 26px;
    }
    
    .tag-input-tag-text {
      max-width: 120px;
    }
  }
  