@import "../../../assets/fonts";

.chatbot-inspector {
    height: 100%;
    top: 0;
    box-sizing: border-box;
    right: 0;
    width: 348px;
    padding: 16px;
    overflow: auto;
    border-left: 1px solid #D4D4D4;
    background: #FCFCFC;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.button-save {
    background-color: #1976d2; /* Primary color */
    color: white; /* Text color */
    padding: 14px 16px; /* Padding */
    font-size: 0.875rem; /* Font size */
    min-width: 64px; /* Minimum width */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor */
    text-transform: uppercase; /* Uppercase text */
    display: inline-flex; /* Inline flex for alignment */
    align-items: center; /* Center items */
    justify-content: center; /* Center items */
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
    margin-top: auto;
}

.button-save:hover {
    background-color: #115293; /* Darker primary color on hover */
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 
              0px 2px 2px 0px rgba(0,0,0,0.14), 
              0px 1px 5px 0px rgba(0,0,0,0.12); /* Shadow on hover */
}

h1 {
    text-align: left;
    font-family: realist, sans-serif;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 16px 0;
    letter-spacing: 0;
    color: #191919;
    opacity: 1;
}

label {
    text-align: left;
    font-family: realist, sans-serif;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0;
    color: #3A3A3A;
}

input, select {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    text-overflow: ellipsis;
    outline: none;
    height: 35px;
    background: #FFFFFF;
    border: 1px solid #D4D4D4;
    opacity: 1;
    text-align: left;
    font-family: realist, sans-serif;
    letter-spacing: 0;
    color: #191919;
    margin-bottom: 16px;
    margin-top: 3px;

    &::placeholder {
        color: #6C6C6C;
    }
}

.icon-input-logo {
    position: absolute;
    margin-top: 12px;
    margin-left: 8px;
    display: block;

    &:after {
        content: '\E85D';
        @include icon;
        color: #191919;
        font-weight: 700;
        font-size: 18px;
    }
}

.icon-input {
    padding-left: 31px;
}

.disabled-chatbot-inspector {
    h1, label, input {
        opacity: 0.6;
    }
}

.ports {
    margin-top: 10px;
    margin-bottom: 32px;

    .out-ports-bar {
        width: 100%;
        height: 47px;
        margin: 0 -16px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F5F5F5;
        border-bottom: 1px solid #D4D4D4;
        border-top: 1px solid #D4D4D4;
        box-sizing: content-box;

        span {
            text-align: left;
            font-family: realist, sans-serif;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0;
            color: #3A3A3A;
        }
    }

    .add-port {
        height: 23px;
        width: 23px;
        border-radius: 50%;
        background: #0057FF;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
        margin-right: 8px;
        outline: none;

        &:before {
            content: '\E145';
            @include icon;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 700;
        }

        &:hover {
            background: #0057FFBF;
        }

        &[disabled] {
            background: #BEBEBE;
            cursor: not-allowed;
        }
    }

    .add-ports {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 5px;
    }

    .port {
        margin-top: 8px;
        position: relative;
        margin-bottom: 16px;

        input {
            margin: auto;
            border-radius: 30px;
        }

        .remove-port {
            cursor: pointer;
            width: 23px;
            height: 23px;
            background: #727272;
            border-radius: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 8px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;

            &:before {
                content: "\E5CD";
                @include icon;
                font-size: 14px;
                color: #FFFFFF;
                font-weight: 800;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}