.omnichannel-chat {
  height: 100%;
  max-height: 100%;
  background-color: #F3F3F3;
  display: flex;
  flex-direction: column;
}

.omnichannel-chat-header-chat-info-root {
  padding: 0% 1%;
  background-color: #F3F3F3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #545454;
  fill: #545454;
  font-size: 1.1em;
  border-bottom: 1px solid rgba(82, 82, 82, .2);
}

.omnichannel-chat-header-chat-info-icon {
  display: block!important;
}

.omnichannel-chat-header-chat-info {
  padding: 1% 0%;
  background-color: #F3F3F3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #545454;
  fill: #545454;
  font-size: 1.1em;
}

.omnichannel-chat-header-user-action-bar {
  padding-right: 0!important;
  display: flex;
  align-items: center;
}

.omnichannel-chat-header-user-action-bar-info-button-container {
  display: flex;
  justify-content: flex-end;
}

.omnichannel-chat-header-user-action-bar .MuiIconButton-root {
  border-radius: 5px!important;
  background-color: rgba(82, 82, 82, .25)!important;
  padding: 5px!important;
}

.omnichannel-chat-header-user-action-bar .MuiIconButton-root .MuiSvgIcon-root {
  font-size: 1em!important;
}

.omnichannel-chat-header-attendance-info {
  padding: 1% 2% 0 2%;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: row!important;
  justify-content: space-between;
}

.omnichannel-chat-header-attendance-info-container {
  width: 20%;
  max-width: 20%;
  flex-direction: column;
  justify-content: center;
}

.omnichannel-chat-header {
  flex: 0 1 0;
}

.omnichannel-chat-conversations {
  padding: 1% 2%;
  flex: 3 0 0;
  overflow-y: auto;
}

.omnichannel-chat-conversations::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.omnichannel-chat-conversations::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.omnichannel-chat-conversations::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.omnichannel-chat-conversations::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.omnichannel-chat-message {
  flex: 0 1 0;
  padding: 1% 0;
}

/*
omnichannel-chat-header - Header
omnichannel-chat-conversations - Conversations
omnichannel-chat-message - Messages
*/

.message {
  display: block;
  max-width: 40%;
  text-align: left;
  margin: 6px;
  border-radius: 10px;
  text-align: left;
}

.sender {
  padding: 1%;
  margin-left: 60%; 
  text-align: right;
}

.sender p {
  background-color: #A8C8D6;
  padding: 1% 4%;
  margin-left: 8px;
  border-radius: 4px;
  display: inline-block;
}

.sender .sender-content {
  background-color: #A8C8D6;
  padding: 1% 4%;
  margin-left: 8px;
  border-radius: 4px;
  display: inline-block;
}

.receiver {
  padding: 1%;
  text-align: left;
  margin-right: 60%;
  display: flex;
}

.receiver p {
  background-color: #CDDFE7;
  padding: 1% 4%;
  border-radius: 4px;
  display: inline-block;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.sender .arrow {
  border-width: 8px 0 8px 10px;
  border-color: transparent transparent transparent #A8C8D6;
  position: relative;
  right: -99.5%;
  margin-top: -24px;
}

.receiver .arrow {
  border-width: 8px 10px 8px 0;
  border-color: transparent #CDDFE7 transparent transparent;
  margin-top: 4px;
  position: relative;
  display: inline-block;
}

p {
  margin: 0;
}

.omnichannel-chat-channels-tabs {
  background-color: #F3F3F3;
  padding: .5%;
  flex: 0 1 0;
  border-bottom: 1px solid rgba(82, 82, 82, .2);
}

.omnichannel-chat-inbox-header {
  background-color: #DADADA;
  padding: 1%;
  flex: 0 1 0;
}

.omnichannel-chat-inbox-header-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #545454;
  fill: #545454;
  font-size: 1.3em;
  font-weight: bolder;
  padding-left: 16px!important;
}

.omnichannel-chat-inbox-header-status {
  padding-right: 0;
}

.omnichannel-chat-user-details-sidebar-header {
  font-size: 1.3em;
  font-weight: bolder;
  color: #545454;
  fill: #545454;
}

/* RÓTULOS */
.omnichannel-chat-user-details-sidebar-labels {
  overflow-y: auto;
}

.omnichannel-chat-user-details-sidebar-labels::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.omnichannel-chat-user-details-sidebar-labels::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.omnichannel-chat-user-details-sidebar-labels::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.omnichannel-chat-user-details-sidebar-labels::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* NOTAS */
.omnichannel-chat-user-details-sidebar-notes {
  overflow-y: auto;
  min-height: auto;
}

.omnichannel-chat-user-details-sidebar-notes::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}

/* Track */
.omnichannel-chat-user-details-sidebar-notes::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.omnichannel-chat-user-details-sidebar-notes::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.omnichannel-chat-user-details-sidebar-notes::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (min-width: 1200px) { /* LG */
  .omnichannel-chat-header-user-action-bar-info-button-container {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1199.9px) {
  .omnichannel-chat-header-user-action-bar-info-button-container {
    display: block;
  }
}

@media (max-width: 599.9px) {
  .omnichannel-chat-header-attendance-info {
    flex-direction: column!important;
  }

  .omnichannel-chat-header-user-action-bar {
    width: 100%;
    justify-content: flex-end;
  }

  .omnichannel-chat-header-chat-info-root {
    flex-direction: column;
  }

  .omnichannel-chat-header-attendance-info-container {
    width: 100%;
    max-width: 100%;
    padding: 2% 5%;
  }

  .omnichannel-chat-header-attendance-info-buttons-container {
    display: flex;
    justify-content: flex-end;
    padding: 2% 5%;
  }

  .omnichannel-chat-inbox-header-status {
    padding-right: 50%;
  }

  .omnichannel-chat-header-chat-info-icon {
    display: none!important;
  }

  .omnichannel-chat-user-details-sidebar-notes {
    min-height: 300px;
  }
}

@media (min-width: 900px) and (max-width: 1199.9px) {
  .omnichannel-chat-inbox-header-name {
    padding-left: 5%!important;
  }
}