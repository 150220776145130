.vote-gradient {
  display: flex;
  justify-content: center;
}

.vote-number {
  font-weight: bold;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.vote-number-selected {
  opacity: .4;
}


.thumbs-up{
  font-size: 32px;
  
}
.thumbs-up:hover{
  cursor: pointer;
  
}

.thumbs-up-selected{
  color: green;
  
}
.thumbs-up-selected-red{
  color: red;
}

/* Small devices such as large phones (640px and up) */
@media (max-width: 959.9px) { /* SM */
  body {
    padding: 0;
    padding-bottom: 0!important;
  }

  .feedback-main-container {
    padding-right: 0!important;
  }

  .feedback-bg-answers-text {
    padding: 1% 5%!important;
  }

  .feedback-bg-help-text {
    margin-right: 20%!important;
  }

  .feedback-form {
    padding-right: 16px!important;
  }
}

/* Medium devices such as tablets (768px and up) */
@media (min-width: 960px) and (max-width: 1199.9px) { /* MD */
  body {
    padding: 0;
    padding-bottom: 0!important;
  }

  .feedback-main-container {
    padding-right: 0!important;
  }
}

/* Large devices such as laptops (1024px and up) */
@media (min-width: 1200px) and (max-width: 1535.9px) { /* LG */
  body {
    padding: 0px 20px 20px 20px;
    padding-bottom: 30px!important;
  }
}

/* Largest devices such as desktops (1280px and up) */
@media (min-width: 1536px) { /* XL */
  body {
    padding: 0px 20px 20px 20px;
    padding-bottom: 30px!important;
  }
}