.input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ccc;
  outline: none;
  font-family: realist, sans-serif;
  font-size: inherit;
  line-height: inherit;
  font-weight: bolder;
}

.ul-dropdown {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.li-item-dropdown {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #ccc;

  &:hover {
    background-color: #f0f0f0;
  }
}
